import React from 'react'

import { Box, Container, Flex } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'

const Content = () => (
  <Box as="section" bg="primary.100" py={6} px={4} id="services">
    <Container>
      <SectionTitle color="primary.900">PRIVACY POLICY</SectionTitle>
      <Box bg="white" position="relative" px={6} py={6} zIndex="2" width="100%">
        <Box
          bg="white"
          position="relative"
          px={6}
          py={6}
          zIndex="2"
          width="100%"
        >
          <Text variant="body">
            Following the principles of legality, loyalty and transparency of
            Regulation (EU) 2016/679 General Data Protection ("RGPD") and
            Organic Law 3/2018, of December 5, Protection of Personal Data and
            Guarantee of Rights Digitales ("LOPDGDD"), we make this Privacy
            Policy available to you to explain the processing of personal data
            that we carry out through the website{' '}
            <a href="https://safebrok.com">https://safebrok.com</a>.
          </Text>
          <Text as="h3" color="primary.900">
            1. Who is responsible for the processing of your data?
          </Text>
          <Text variant="body">
            Responsible: Business Affiliation Europe, S.L. (hereinafter
            "Aibrok")
            <br />
            NIF: <b>B88229752</b>
            <br />
            Registered Office: Avenida Brasil Nº17, mezzanine, 28020 Madrid,
            Spain
            <br />
            Email: <b>info@aibrok.com.mx</b>
            <br />
            Teléfono: <b>+34 955 44 19 90 </b>
            <br />
            Data protection email: <b>privacidad@safebrok.com</b>
          </Text>
          <Text as="h3" color="primary.900">
            2. For what purpose do we process your personal data?
          </Text>
          <Text variant="body">
            Through this website, we only process the information you provide us
            with the following processing purposes:
            <br />
            <br />
            PURPOSES OF THE TREATMENT
            <br />
            <br />
            <ol>
              <li>
                Manage your candidacy as a potential worker or collaborator
                through the "Contact" form / Send you commercial communications
                about products or services from Aibrok suppliers. To these
                effects. We will treat: (i) Name, (ii) Surname, (iii) E-mail,
                (iv) Telephone, (v) Training and professional development
                [Message, experience, desired position, Curriculum Vitae.
                <br />
                <br />
                *In case of providing data from third parties, the user agrees
                to previously obtain the express consent of the interested
                party.
              </li>
              <li>
                Manage the analysis of your browsing data through the
                installation of cookies. You can consult all the details in our
                Cookies Policy. 3. What is the legitimacy for the processing of
                your data? The legitimation bases for the processing of your
                data according to the identified purposes are:
              </li>
            </ol>
          </Text>
          <Text as="h3" color="primary.900">
            3. ¿Cuál es la legitimación para el tratamiento de sus datos?
          </Text>
          <Text variant="body">
            Las bases de legitimación para el tratamiento de sus datos de
            acuerdo a las finalidades identificadas son:
            <br />
            <br />
            LEGAL BASE
            <br />
            <br />
            <ol>
              <li>
                Art. 6.1.a) RGPD: consent of the interested party / Art. 6.1.a)
                RGPD: consent of the interested party.
              </li>
              <li>Art. 6.1.a) RGPD: consent of the interested party.</li>
            </ol>
            The data that we request are adequate, pertinent and strictly
            necessary and in no case are you obliged to provide them to us, but
            your failure to communicate them may affect the purpose of the
            treatment or the impossibility of managing your interests.
          </Text>
          <Text as="h3" color="primary.900">
            4. How long will we keep your personal data?
          </Text>
          <Text variant="body">
            <ol>
              <li>
                We will keep the data for a maximum period of one year, in case
                you do not become part of the Aibrok team as a worker or
                collaborator.
              </li>
              <li>
                During the expiration of cookies, in accordance with the Cookies
                Policy.
              </li>
            </ol>
          </Text>
          <Text as="h3" color="primary.900">
            5. To which recipients will your data be communicated?
          </Text>
          <Text variant="body">
            Aibrok will not communicate your data to any third party, unless you
            give your express consent or in compliance with a legal obligation.
          </Text>
          <Text as="h3" color="primary.900">
            6. What are your rights when you provide us with your data?
          </Text>
          <Text variant="body">
            The data protection rights of which the interested parties are
            holders are:
            <br /> <br />
            Right of access: obtain information about whether your data is being
            processed, the purpose of the treatment, the origin of the data and
            transfers made or planned.
            <br /> <br />
            Right of rectification: request that those of your data that turn
            out to be inaccurate or incomplete be modified.
            <br /> <br />
            Right of erasure: request that personal data be deleted or erased
            when they are no longer necessary, when consent is withdrawn, they
            have been unlawfully processed, etc. In that case, it should be
            possible to lock the data and subsequently remove any copies, links
            and replicas of the data. All this, as long as it is not prevented
            by a legal obligation, or is necessary for the exercise or defense
            of claims.
            <br /> <br />
            Right to request the limitation of your treatment: request that your
            personal data not be applied to the treatment operations that in
            each case would correspond.
            <br /> <br />
            Right to request the limitation of your treatment: request that your
            personal data not be applied to the treatment operations that in
            each case would correspond.
            <br /> <br />
            Right to data portability: request that your data be provided to
            another entity, or to yourself, in a structured, commonly used and
            machine-readable format. Said request can only be made on data whose
            treatment is based on consent or a contract, and provided that the
            treatment is carried out by automated means.
            <br /> <br />
            Right not to be the subject of automated decisions: request not to
            be the subject of a decision based solely on automated processing,
            including profiling, that produces legal effects or significantly
            affects you in a similar way (in principle, it will not apply to
            processing from Aibrok).
            <br /> <br />
            The holders of the personal data obtained may exercise their rights
            by directing a written communication to the registered office of
            Aibrok or to the email enabled for this purpose,
            privacy@safebrok.com
            <br /> <br />
            Models, forms and more information available on your rights on the
            website of the national control authority, Spanish Agency for Data
            Protection, hereinafter, AEPD, www.aepd.es.
          </Text>
          <Text as="h3" color="primary.900">
            7. Can I withdraw consent?
          </Text>
          <Text variant="body">
            You have the possibility and the right to withdraw consent for any
            specific purpose granted at the time, without affecting the legality
            of the treatment based on the consent prior to its withdrawal.
          </Text>
          <Text as="h3" color="primary.900">
            8. Where can I complain if I consider that my data is not being
            processed correctly?
          </Text>
          <Text variant="body">
            If any interested party considers that their data is not treated
            correctly by Aibrok, they can direct their claims to the Data
            Protection Delegate (privacy@safebrok.com) or to the corresponding
            data protection authority, the AEPD being the one indicated in the
            national territory , www.aepd.es.
          </Text>
          <Text as="h3" color="primary.900">
            9. Security and updating of your personal data
          </Text>
          <Text variant="body">
            In order to safeguard the security of your personal data, we inform
            you that Aibrok has adopted all the necessary technical and
            organizational measures to guarantee the security of the personal
            data supplied. All this to avoid its alteration, loss, and / or
            unauthorized treatment or access, as required by the regulations,
            although absolute security does not exist.
            <br /> <br />
            It is important that, so that we can keep your personal data up to
            date, you inform us whenever there is a change in it.
          </Text>
          <Text as="h3" color="primary.900">
            10. Confidentiality
          </Text>
          <Text variant="body">
            Aibrok informs you that your data will be treated with the utmost
            care and confidentiality by all personnel involved in any of the
            treatment phases. We remind you that we will not transfer or
            communicate your data to any third party, except in the cases
            provided by law, or unless the interested party has expressly
            authorized us.
          </Text>
        </Box>
      </Box>
    </Container>
  </Box>
)

export default Content
