import React from 'react'
import { navigate } from 'gatsby';

import { Layout } from '@components/LayoutEN'
import { SEO } from '@components/SEO'
import { Content } from '../screens/PrivacyPoliciesEN'

const PrivacyPage = () => {

  if (typeof window !== 'undefined' && window.location.pathname === '/privacyPolicies') {
    navigate('/es/privacyPolicies');
  }

  return (

    <Layout>
      <SEO title="Privacy Policies" />
      <Content />
    </Layout>

  )
}

export default PrivacyPage
